/** @jsx jsx */

import { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { jsx, Flex, Image } from "theme-ui"
import { transparentize } from "@theme-ui/color"

import logo from "../images/logo.svg"
import navbarBackground from "../images/navbar-background.svg"
import { Link } from "./Link"
import {
  HamburgerMenuButton,
  CloseMenuButton,
  FacebookIcon,
  InstagramIcon,
} from "./IconButtons"
import { Divider } from "./Divider"
import { DividerType } from "../hooks/divider"
import { allCustomPages } from "../hooks/allCustomPages"

const BaseNavNamesToLinks = {
  Articles: "/articles/1",
  "The Sauce": "/journazines",
  About: "/about",
}

const MobileNavMenuButton = ({ isOpen, onClick }) => {
  if (isOpen)
    return <CloseMenuButton onClick={onClick} aria-label="Close nav menu" />

  return <HamburgerMenuButton onClick={onClick} aria-label="Open nav menu" />
}
HamburgerMenuButton.propTypes = {
  onClick: PropTypes.func,
}
MobileNavMenuButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
}

const Logo = () => (
  <Link
    to="/"
    sx={{
      mr: "auto",
    }}
  >
    <Image
      src={logo}
      alt="Foodscape Pages Logo"
      width="50"
      height="35"
      loading="lazy"
      sx={{
        display: "block",
        width: ["50px", "50px", "100px"],
        height: ["35px", "35px", "70px"],
      }}
    />
  </Link>
)

const MobileNavItems = ({ isOpen, navNamesToLinks }) => {
  return (
    <div
      sx={{
        // In landscape mode, we don't want the navbar height to exceed the viewport height
        // otherwise the user will not be able to scroll through the navbar contents
        // if there are more items than can be displayed on the screen
        maxHeight: isOpen ? "70vh" : "0",
        overflowY: "scroll",
        transition: "all 0.5s ease-out",
        px: 3,
        pt: 0,
        pb: isOpen ? 2 : 0,
        background: t => `
      linear-gradient(
        to bottom,
        ${transparentize("white", 1)(t)} 0%,
        ${transparentize("white", 0.2)(t)} 70%,
        ${transparentize("white", 0)(t)}
      )
      `,
      }}
    >
      <ul
        sx={{
          m: 0,
          p: 0,
        }}
      >
        {Object.entries(navNamesToLinks).map(([name, link], i) => {
          return (
            <li
              key={name}
              sx={{
                display: "block",
                width: "100%",
                py: 2,
              }}
            >
              <Link
                variant="textDark"
                to={link}
                sx={{ fontSize: 2, fontWeight: "bold", mx: 1 }}
              >
                {name}
              </Link>
            </li>
          )
        })}
        <li
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <FacebookIcon />
          <InstagramIcon sx={{ ml: 5 }} />
        </li>
      </ul>
      <Divider
        type={DividerType.SEEDLING}
        styles={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "50px",
          mb: "10px",
        }}
      />
    </div>
  )
}
MobileNavItems.propTypes = {
  isOpen: PropTypes.bool,
  navNamesToLinks: PropTypes.object,
}

const MobileNavHeader = ({ navNamesToLinks }) => {
  const [isOpen, updateIsOpen] = useState(false)

  return (
    <Flex
      sx={{
        display: ["flex", "flex", "none"],
        flexDirection: "column",
        position: "fixed",
        top: "2.5vw",
        left: "50%",
        width: "95vw",
        ml: "calc(-95vw / 2)",
        zIndex: 10,
        overflow: "hidden",
        borderRadius: 2,
        backgroundColor: "white",
        "&:before": {
          content: '""',
          display: "block",
          zIndex: "-1",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0.4,
          backgroundImage: `url(${navbarBackground})`,
          backgroundSize: "cover",
          backgroundPosition: ["0 -40px", "0 -100px"],
        },
      }}
    >
      <Flex
        sx={{
          py: 2,
          px: 3,
          transition: "border-radius 1s ease-out",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Logo />
        <MobileNavMenuButton
          isOpen={isOpen}
          onClick={() => updateIsOpen(!isOpen)}
        />
      </Flex>
      <MobileNavItems isOpen={isOpen} navNamesToLinks={navNamesToLinks} />
    </Flex>
  )
}
MobileNavHeader.propTypes = {
  navNamesToLinks: PropTypes.object,
}

const DesktopNavHeader = ({ navNamesToLinks }) => {
  return (
    <Flex
      sx={{
        position: "sticky",
        top: 0,
        height: t => `calc(70px + ${t.space[4]}px * 2)`,
        py: 4,
        px: 4,
        zIndex: 10,
        justifyContent: "flex-end",
        alignItems: "center",
        bg: "background",
        display: ["none", "none", "flex"],
      }}
    >
      <Logo />
      <ul
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          listStyle: "none",
        }}
      >
        {Object.entries(navNamesToLinks).map(([name, link]) => {
          return (
            <li key={name}>
              <Link
                variant="textDark"
                to={link}
                sx={{
                  fontSize: [null, null, 1, 2],
                  fontWeight: "bold",
                  mx: [null, null, 3, 4],
                }}
              >
                {name}
              </Link>
            </li>
          )
        })}
        <li sx={{ ml: 4, mr: 3 }}>
          <FacebookIcon />
        </li>
        <li sx={{ ml: 3 }}>
          <InstagramIcon />
        </li>
      </ul>
    </Flex>
  )
}
DesktopNavHeader.propTypes = {
  navNamesToLinks: PropTypes.object,
}

export const NavHeader = () => {
  const customPageNamesToLinks = allCustomPages().reduce((acc, page) => {
    return {
      ...acc,
      [page.title]: `/${page.uid}`,
    }
  }, {})
  const navNamesToLinks = {
    ...BaseNavNamesToLinks,
    ...customPageNamesToLinks,
  }
  return (
    <Fragment>
      <MobileNavHeader navNamesToLinks={navNamesToLinks} />
      <DesktopNavHeader navNamesToLinks={navNamesToLinks} />
    </Fragment>
  )
}
