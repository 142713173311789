import * as CountryCodes from "country-codes-list"

export const isProduct = item => {
  return item.type === "product"
}

export const isCrystallizeImage = image => image.variants

export const transformVariant = variant => ({
  ...variant,
  ...(variant.attributes
    ? {
        attributes: variant.attributes.reduce((attributesObj, attribute) => {
          attributesObj[attribute.attribute] =
            attribute.value === "true"
              ? true
              : attribute.value === "false"
              ? false
              : attribute.value
          return attributesObj
        }, {}),
      }
    : {}),
})

export const transformProduct = product => {
  const components = product.components.reduce((componentObj, component) => {
    componentObj[component.id] = component
    return componentObj
  }, {})
  let defaultVariant
  const variants = product.variants.reduce((variantObj, variant) => {
    const transformedVariant = transformVariant(variant)
    if (variant.isDefault) {
      defaultVariant = transformedVariant
    }
    variantObj[variant.sku] = transformedVariant
    return variantObj
  }, {})

  return {
    ...product,
    components,
    variants,
    defaultVariant,
  }
}

export const calculateProductCost = (
  transformedProduct,
  variantSku,
  quantity
) => {
  const variant = transformedProduct.variants[variantSku]

  if (variant.attributes.combinedPricing) {
    const combinedPriceVariantSkus =
      variant.attributes.combinedPriceVariantSkus.split(",")
    return combinedPriceVariantSkus.reduce((cost, combinedPriceVariantSku) => {
      const priceVariant = transformedProduct.variants[combinedPriceVariantSku]

      if (priceVariant.attributes.nonQuantityVariant) {
        return cost + priceVariant.price
      }

      if (priceVariant.price) {
        return cost + priceVariant.price * quantity
      }

      return cost
    }, 0)
  }

  if (variant.attributes.nonQuantityVariant) {
    return variant.price
  }

  return quantity * variant.price
}

// See region list here: https://meta.wikimedia.org/wiki/List_of_countries_by_regional_classification
// See Singpost shipping rates here: https://www.singpost.com/send-receive/send-overseas
// There seem to be three tiers for international shipping: Malaysia, near Asia Pacific, far Asia Pacific, and everywhere else
// We can't differentiate between near and far Asia Pacific, and the price differential is about $5, but
// those are fairly unlikely scenarios as of now
const RegionToShippingCosts = {
  Malaysia: 5.7, // Malaysia is an exception
  Africa: 17.5,
  "Arab States": 17.5,
  "Asia & Pacific": 14.0, // middle ground between 12.50 for near and 17.50 for far
  Europe: 17.5,
  "Middle East": 17.5,
  "North America": 17.5,
  "South/Latin America": 17.5,
}

export const calculateShippingCost = country => {
  if (country === "Singapore") {
    return 0
  }

  const countriesToRegions = CountryCodes.customList(
    "countryNameEn",
    "{region}"
  )

  const region = countriesToRegions[country]
  return RegionToShippingCosts[country] || RegionToShippingCosts[region] || 0
}

export const calculateOrderCost = (customerOrCountry, cart) => {
  const country =
    typeof customerOrCountry === "string"
      ? customerOrCountry
      : customerOrCountry.shippingAddress?.country
  const shippingCost = country ? calculateShippingCost(country) : 0

  return (
    cart.reduce((orderCost, item) => {
      return (
        orderCost +
        calculateProductCost(
          item.product,
          item.selectedVariant.sku,
          item.quantity
        )
      )
    }, 0) + shippingCost
  )
}
