import PropTypes from "prop-types"
import { SeoFieldsPropType, PrismicPlainTextFieldPropType } from "./propTypes"
import linkResolver from "./linkResolver"
import { extractExcerpt } from "./articleUtils"

// See https://ogp.me/#types
export const OpenGraphTypes = {
  MUSIC_SONG: "music.song",
  MUSIC_ALBUM: "music.album",
  MUSIC_PLAYLIST: "music.playlist",
  MUSIC_RADIO_STATION: "music.radio_station",
  VIDEO_MOVIE: "video.movie",
  VIDEO_EPISODE: "video.episode",
  VIDEO_TV_SHOW: "video.tv_show",
  VIDEO_OTHER: "video.other",
  ARTICLE: "article",
  BOOK: "book",
  PROFILE: "profile",
  WEBSITE: "website",
}

export const isValidOpenGraphType = type => {
  return Object.values(OpenGraphTypes).includes(type)
}

export const extractBaseSeoFields = ({
  type,
  uid,
  meta_title,
  meta_description,
  language,
  canonical_url,
  page,
  opengraph_image,
}) => {
  const fallbackCanonicalUrlPath =
    uid || page ? linkResolver({ type, uid }, page) : null

  return {
    ...(isValidOpenGraphType(type) ? { type } : { type: "website" }),
    title: meta_title,
    description: meta_description,
    lang: language,
    // We assume the URL provided from Prismic is always an internal link
    // since that's how the linkResolver works
    canonicalUrlPath: canonical_url?.url
      ? canonical_url.url
      : fallbackCanonicalUrlPath,
    image: opengraph_image?.url || null,
  }
}

extractBaseSeoFields.propTypes = SeoFieldsPropType

export const extractArticleSeoFields = ({
  first_publication_date,
  last_publication_date,
  title,
  body,
  cover_image,
  tags,
  contributors,
}) => {
  return {
    title: title.text,
    description: extractExcerpt(body, 100) || null,
    image: cover_image.gatsbyImageData?.images?.fallback?.src || null,
    tags: tags.map(tag => tag.category.document.data.name),
    authors: contributors.map(
      contributor => contributor.contributor.document.data.name
    ),
    publishedTime: first_publication_date,
    modifiedTime: last_publication_date,
  }
}
extractArticleSeoFields.propTypes = {
  contributors: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  first_publication_date: PropTypes.string.isRequired,
  last_publication_date: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PrismicPlainTextFieldPropType.isRequired,
    subtitle: PrismicPlainTextFieldPropType.isRequired,
    cover_image: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    tags: PropTypes.arrayOf({
      tag: PropTypes.string,
    }).isRequired,
  }),
}
