/** @jsx jsx */
import { useMutation } from "react-query"
import PropTypes from "prop-types"
import { jsx, Heading, Input, Button } from "theme-ui"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import { FormikFieldWithError } from "./FormikFieldWithError"
import { withRecaptcha } from "../utils/recaptchaUtils"
import { SeedlingLoader } from "./SeedlingLoader"
import { ApiClient } from "../utils/apiClient"
import { useFormAlertContext } from "../hocs/FormAlertProvider"
import { loadRecaptcha } from "../utils/recaptchaUtils"

export const NewsletterSignupForm = ({ style }) => {
  const { updateAlertState } = useFormAlertContext()
  const subscribeToNewsletter = useMutation(
    payload => ApiClient.post("/api/newsletter-subscription", payload),
    {
      onSuccess: data => {
        updateAlertState({ ...data, isVisible: true })
      },
      onError: async error => {
        updateAlertState({ ...(await error.json()), isVisible: true })
      },
    }
  )

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Please provide a valid email address")
          .required("Required"),
        name: Yup.string().required("Required"),
      })}
      onSubmit={async values => {
        const action = "newslettersubscription"
        withRecaptcha(action, async token => {
          const payload = {
            recaptchaToken: token,
            action,
            contact: {
              email: values.email,
              first_name: values.name,
            },
          }

          subscribeToNewsletter.mutate(payload)
        })
      }}
    >
      <Form sx={{ ...style }}>
        <Heading variant="subheading" sx={{ color: "white" }}>
          Subscribe to our newsletter
        </Heading>
        <FormikFieldWithError
          component={Input}
          name="email"
          id="email"
          type="email"
          placeholder="Email"
          aria-label="Email"
          fieldVariant="inputWhiteNoLabel"
          onFocus={loadRecaptcha}
        />
        <FormikFieldWithError
          component={Input}
          name="name"
          id="name"
          type="text"
          placeholder="Name"
          aria-label="Name"
          fieldVariant="inputWhiteNoLabel"
        />
        {subscribeToNewsletter.isLoading ? (
          <SeedlingLoader containerStyles={{ mt: 3 }} />
        ) : (
          <Button
            type="submit"
            variant="primaryTransparent"
            sx={{ px: 3, mt: 3 }}
          >
            Subscribe
          </Button>
        )}
      </Form>
    </Formik>
  )
}
NewsletterSignupForm.propTypes = {
  style: PropTypes.object,
}
