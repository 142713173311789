/** @jsx jsx */

import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ResponsiveImage } from "./ResponsiveImage"
import { ResponsiveImagePropTypes } from "../utils/propTypes"
import { divider, DividerType } from "../hooks/divider"

export const Divider = ({ type, image, styles = {} }) => {
  const dividerImage = type ? divider(type) : image
  const dividerHeight = dividerImage.gatsbyImageData.height

  const {
    height,
    backgroundSize,
    backgroundRepeat,
    backgroundPosition,
    ...restStyles
  } = styles

  return (
    <ResponsiveImage
      isBackgroundImage={true}
      image={dividerImage}
      sx={{
        ...restStyles,
      }}
      bgImgStyles={{
        backgroundRepeat: backgroundRepeat || "repeat-x",
        backgroundPosition: backgroundPosition || "center",
        backgroundSize: backgroundSize || "contain",
      }}
    >
      <hr
        sx={{
          height: height || Math.min(80, dividerHeight),
          m: 0,
          border: "none !important",
        }}
      />
    </ResponsiveImage>
  )
}
Divider.propTypes = {
  type: PropTypes.oneOf(Object.values(DividerType)),
  image: PropTypes.shape(ResponsiveImagePropTypes),
  styles: PropTypes.object,
}
