/* eslint-disable react/display-name */
/** @jsx jsx */

import { PropTypes } from 'prop-types';
import { jsx, Text, Flex } from "theme-ui"

import "./SeedlingLoader.css"

const LOADING_MESSAGES = [
  "Converting sunlight to sugars...",
  "Unfurling seed leaves...",
  "Sending roots down...",
  "Releasing exudates...",
]

const randomLoadingMessage = () => {
  return LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)]
}

export const SeedlingLoader = ({containerStyles}) => {
  const loadingMessage = randomLoadingMessage()
  return (
    <Flex
      sx={{
        width: "max-content",
        flexDirection: "column",
        alignItems: "center",
        ...containerStyles
      }}
    >
      <svg
        className="seedling-loader"
        width="100"
        height="100"
        version="1.1"
        viewBox="0 0 26.458 26.458"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          transform="translate(-48.371 -152.39)"
          strokeLinecap="round"
          strokeWidth=".74313"
        >
          <path
            d="m61.493 162.34s0.87047-3.5382 4.2561-4.7888c1.7914-0.66171 2.3725-0.52852 3.8833-1.304 1.9964-1.0247 4.3129-3.1519 4.3129-3.1519s-1.1673 2.864-2.7742 4.9756c-0.78601 1.0329-2.3109 1.9361-3.4238 2.6699-2.1818 1.4387-6.2544 1.5991-6.2544 1.5991"
            fill="none"
            stroke="#b9d13f"
            strokeLinejoin="round"
          />
          <path
            d="m60.743 161.65s-3.1319 0.37066-4.905-0.38125c-0.79714-0.33806-3.0177-1.592-4.1311-3.2689-1.3631-2.053-1.5605-4.5592-1.5605-4.5592s1.6547 2.8164 3.6685 3.9355c2.0076 1.1157 2.0934-0.36984 4.4123 0.78283 2.1349 1.0612 2.5159 3.4909 2.5159 3.4909"
            fill="none"
            stroke="#b9d13f"
            strokeLinejoin="round"
          />
          <path
            d="m60.964 178.35s-4.1338-4.0953-2.1601-10.087c1.9957-6.058 2.1706-6.5164 2.1706-6.5164"
            fill="none"
            stroke="#71853a"
          />
        </g>
      </svg>
      <Text sx={{ my: 3, color: "white", textAlign: "center" }}>
        {loadingMessage}
      </Text>
    </Flex>
  )
}
SeedlingLoader.propTypes = {
  containerStyles: PropTypes.object
}