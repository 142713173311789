import { createMediaMatcher } from "react-media-match"
import theme from "../gatsby-plugin-theme-ui"

export const MediaMatcher = createMediaMatcher({
  // We need to differentiate between mobile landscape and tablet portait
  // since they have the same min-width
  // Note: there seems to be something strange with react-media-match
  // where the matching doesn't behave correctly if the max-width range isn't
  // also specified (eg. with just min-width specified, a laptop device will
  // end up matching "mobileLandscape")
  mobilePortrait: `(max-width: ${theme.breakpointWidths.sm})`,
  mobileLandscape: `(max-width: ${theme.breakpointWidths.md}) and (orientation: landscape)`,
  tablet: `(min-width: ${theme.breakpointWidths.sm}) and (orientation: portrait), (min-width: ${theme.breakpointWidths.md}) and (max-width: ${theme.breakpointWidths.lg})`,
  desktop: `(min-width: ${theme.breakpointWidths.lg})`,
})
