import { Base64 } from "js-base64"
import { importJWK, importSPKI, importPKCS8 } from "jose/key/import"

export const getApiClientKey = async () => {
  const jwk = JSON.parse(Base64.decode(process.env.GATSBY_APP_API_CLIENT_SECRET_KEY))
  return await importJWK(jwk, "HS256")
}

export const getServerPrivateKey = async () => {
  const pemString = Base64.decode(
    process.env.APP_API_SERVER_PRIVATE_KEY,
  )

  return await importPKCS8(pemString, "ES256")
}

export const getServerPublicKey = async () => {
  const pemString = Base64.decode(
    process.env.GATSBY_APP_API_SERVER_PUBLIC_KEY,
    "base64"
  )

  return await importSPKI(pemString, "ES256")
}
