/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

export const DividerType = {
  DEFAULT: "default",
  DEFAULT_WHITE: "default-white",
  DEFAULT_YELLOW: "default-yellow",
  DEFAULT_OLIVE: "default-olive",
  SEEDLING: "seedling",
}

export const divider = type => {
  const staticData = useStaticQuery(
    graphql`
      query DividerImage {
        allPrismicDivider {
          edges {
            node {
              _previewable
              uid
              data {
                image {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `
  )

  const { data } = useMergePrismicPreviewData(staticData)
  if (Object.values(DividerType).includes(type)) {
    return data.allPrismicDivider.edges.filter(edge => edge.node.uid === type)[0]
      .node.data.image
  }

  return data.allPrismicDivider.edges.filter(
    edge => edge.node.uid === DividerType.DEFAULT
  )[0].node.data.image
}
