/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

export const allCustomPages = () => {
  const staticData = useStaticQuery(
    graphql`
      query AllCustomPage {
        allPrismicCustomPage {
          edges {
            node {
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
      }
    `
  )

  const { data } = useMergePrismicPreviewData(staticData)
  return data.allPrismicCustomPage.edges.map(edge => ({
    uid: edge.node.uid,
    title: edge.node.data.title.text,
  }))
}
