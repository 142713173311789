export const withRecaptcha = (action, callback) => {
  // This variable is defined in the google recaptcha script
  // loaded in the html head
  // eslint-disable-next-line no-undef
  grecaptcha.ready(function () {
    // eslint-disable-next-line no-undef
    grecaptcha
      .execute(process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY, {
        action,
      })
      .then(callback)
  })
}

export const loadRecaptcha = () => {
  const existingScript = document.getElementById("googleRecaptcha")

  if (!existingScript) {
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}`
    script.id = "googleRecaptcha"
    document.body.appendChild(script)
  }
}
