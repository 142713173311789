/* eslint-disable react/display-name */
/** @jsx jsx */

import { useEffect, useRef, createElement } from "react"
import PropTypes from "prop-types"
import { jsx, Label } from "theme-ui"
import { useField, useFormikContext, ErrorMessage } from "formik"

const isFirstErroringField = (name, errors) => {
  if (!errors) {
    return false
  }

  const names = name.split(".")
  const firstErrorFieldName = Object.keys(errors)[0]

  if (names.length === 1) {
    return firstErrorFieldName === name
  }

  return isFirstErroringField(
    names.slice(1).join("."),
    errors[firstErrorFieldName]
  )
}

export const FormikFieldWithError = ({
  component,
  label,
  name,
  id,
  type,
  placeholder,
  fieldVariant,
  labelVariant,
  containerStyles,
  ...rest
}) => {
  const [field, meta] = useField(name)
  const ref = useRef(null)
  const { errors, isSubmitting } = useFormikContext()

  useEffect(() => {
    if (isSubmitting && isFirstErroringField(name, errors)) {
      ref.current?.focus()
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [name, meta.error, isSubmitting, errors])

  return (
    <div sx={{ ...containerStyles }}>
      {label ? (
        <Label htmlFor={name} variant={labelVariant}>
          {label}
        </Label>
      ) : null}
      {createElement(component, {
        label,
        name,
        id,
        type,
        placeholder,
        variant: fieldVariant,
        ref,
        ...rest,
        ...field,
      })}
      <ErrorMessage
        name={name}
        render={msg => <div sx={{ color: "errorLight", my: 2 }}>{msg}</div>}
      />
    </div>
  )
}
FormikFieldWithError.propTypes = {
  component: PropTypes.elementType,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  labelVariant: PropTypes.string,
  fieldVariant: PropTypes.string,
  containerStyles: PropTypes.object,
}
