/** @jsx jsx */

import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineClose,
} from "react-icons/ai"
import { jsx, IconButton } from "theme-ui"

import { Link } from "./Link"

export const FacebookIcon = props => {
  return (
    <Link
      variant="iconDark"
      to={"https://www.facebook.com/Foodscape-Pages-103507614693249/"}
      sx={{
        fontSize: 4,
        display: "flex",
        alignItems: "center",
      }}
      aria-label="Foodscape Pages Facebook Page"
      {...props}
    >
      <AiFillFacebook />
    </Link>
  )
}

export const InstagramIcon = props => {
  return (
    <Link
      variant="iconDark"
      to={"https://www.instagram.com/foodscapepages"}
      sx={{
        fontSize: 4,
        display: "flex",
        alignItems: "center",
      }}
      aria-label="Foodscape Pages Instagram"
      {...props}
    >
      <AiOutlineInstagram />
    </Link>
  )
}

export const HamburgerMenuButton = props => (
  <IconButton {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 80"
      width="20"
      height="40"
    >
      <rect sx={{ fill: "secondary" }} width="100" height="10" rx="4" />
      <rect sx={{ fill: "secondary" }} y="33" width="100" height="10" rx="4" />
      <rect sx={{ fill: "secondary" }} y="66" width="100" height="10" rx="4" />
    </svg>
  </IconButton>
)

export const CloseMenuButton = props => (
  <IconButton {...props} sx={{ fontSize: 4, color: "secondary" }}>
    <AiOutlineClose />
  </IconButton>
)
