import bent from "bent"
import { SignJWT } from "jose/jwt/sign"
import { getApiClientKey } from "./jwtKeys"

export const TOKEN_MAX_AGE = "10min"

const headers = {
  "Content-Type": "application/json",
}

export const generateAuthToken = async () => {
  const privateKey = await getApiClientKey()

  return await new SignJWT({})
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setIssuer(process.env.GATSBY_SITE_URL)
    .setExpirationTime(TOKEN_MAX_AGE)
    .sign(privateKey)
}

export const ApiClient = {
  get: async url => {
    const token = await generateAuthToken()

    return bent(window.location.origin, "GET", "json", 200, {
      ...headers,
      Authorization: `Bearer ${token}`,
    })(url)
  },

  post: async (url, body) => {
    const token = await generateAuthToken()

    return bent(window.location.origin, "POST", "json", 200, 201, 202, {
      ...headers,
      Authorization: `Bearer ${token}`,
    })(url, body)
  },

  put: async (url, body) => {
    const token = await generateAuthToken()

    return bent(window.location.origin, "PUT", 200, 201, 202, 204, {
      ...headers,
      Authorization: `Bearer ${token}`,
    })(url, body)
  },
}
