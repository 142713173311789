/** @jsx jsx */

import { jsx, Grid, Flex, Image, Heading, Paragraph } from "theme-ui"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { AiOutlineMail } from "react-icons/ai"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import logoWhite from "../images/logo-white.svg"
import theme from "../gatsby-plugin-theme-ui"
import { Link } from "./Link"
import { NewsletterSignupForm } from "./NewsletterSignupForm"
import { FacebookIcon, InstagramIcon } from "./IconButtons"
import { Divider } from "./Divider"
import { DividerType } from "../hooks/divider"

const LogoAndSocialLinks = () => (
  <Flex sx={{ my: 4, gridColumnStart: [null, null, "span 2"] }}>
    <Image
      src={logoWhite}
      alt="Foodscape Pages Logo, white"
      loading="lazy"
      width="100"
      height="70"
      sx={{ width: "100px", height: "70px" }}
    />
    <Flex sx={{ ml: "auto" }}>
      <FacebookIcon variant="iconWhite" />
      <InstagramIcon variant="iconWhite" style={{ marginLeft: "24px" }} />
    </Flex>
  </Flex>
)

const ContactUs = ({ sectionCopy }) => (
  <div sx={{ mb: 4 }}>
    <Heading variant="subheading" sx={{ color: "white", mb: 3 }}>
      Contact Us
    </Heading>
    <Paragraph sx={{ mb: 2 }}>{sectionCopy}</Paragraph>
    <Link
      variant="textWhite"
      to="mailto:foodscapepages@gmail.com"
      sx={{ ml: 0 }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <AiOutlineMail size={32} color="white" />
        <span sx={{ ml: 3 }}>foodscapepages@gmail.com</span>
      </Flex>
    </Link>
  </div>
)
ContactUs.propTypes = {
  sectionCopy: PropTypes.string,
}

const FoodscapeCollective = ({ sectionCopy }) => (
  <div sx={{ mb: 4, gridColumnStart: [null, null, "span 2"] }}>
    <Heading variant="subheading" sx={{ color: "white", mb: 3 }}>
      Foodscape Collective
    </Heading>
    <Paragraph>{sectionCopy}</Paragraph>
  </div>
)
FoodscapeCollective.propTypes = {
  sectionCopy: PropTypes.string,
}

export const Footer = () => {
  const staticData = useStaticQuery(
    graphql`
      query Footer {
        prismicFooter {
          _previewable
          data {
            contact_us_section {
              text
            }
            about_foodscape_collective {
              text
            }
          }
        }
      }
    `
  )
  const { data } = useMergePrismicPreviewData(staticData)
  const { prismicFooter } = data

  return (
    <div sx={{ bg: "darkGray" }}>
      <Grid
        gap={[0, 0, "0 100px"]}
        columns={[1, 1, 2]}
        sx={{
          ...theme.basePageStyles.contentContainer,
          color: "white",
        }}
      >
        <LogoAndSocialLinks />
        <NewsletterSignupForm style={{ mb: 4 }} />
        <ContactUs sectionCopy={prismicFooter.data.contact_us_section.text} />
        <FoodscapeCollective
          sectionCopy={prismicFooter.data.about_foodscape_collective.text}
        />

        <Divider
          type={DividerType.DEFAULT_WHITE}
          styles={{
            gridColumnStart: [null, null, "span 2"],
            mb: 4,
          }}
        />
        <Paragraph
          variant="fineprint"
          sx={{ mb: 4, gridColumnStart: [null, null, "span 2"] }}
        >
          Copyright (C) {new Date().getFullYear()} - Foodscape Pages. All Rights
          Reserved
        </Paragraph>
      </Grid>
    </div>
  )
}
