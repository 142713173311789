/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { ContentTransformer } from "@crystallize/react-content-transformer"
import { RichText as PrismicRichText } from "prismic-reactjs"

import { RichTextLinkSerializer } from "../components/RichTextLinkSerializer"

export const RichText = ({ sxOverrides, Component, text }) => {
  if (!text) {
    return null
  }
  if (text.richText) {
    return (
      <PrismicRichText
        sx={{
          variant: "text.body",
          a: {
            color: "oliveGreen",
            transition: "all 0.1s ease-in",
            "&:hover": {
              color: "soilBrown",
            },
          },
          ...sxOverrides,
        }}
        render={text.richText}
        Component={Component || "div"}
        serializeHyperlink={RichTextLinkSerializer}
      />
    )
  }

  if (text.json) {
    return (
      <div
        sx={{
          variant: "text.body",
          a: {
            color: "oliveGreen",
            transition: "all 0.1s ease-in",
            "&:hover": {
              color: "soilBrown",
            },
          },
          ...sxOverrides,
        }}
      >
        <ContentTransformer json={text.json} />
      </div>
    )
  }
}
RichText.propTypes = {
  sxOverrides: PropTypes.object,
  Component: PropTypes.string,
  text: PropTypes.object,
}
