/** @jsx jsx */

import "@fontsource/newsreader"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import theme from "../gatsby-plugin-theme-ui"
import { Seo } from "./Seo"
import { NavHeader } from "./NavHeader"
import { Footer } from "./Footer"
import { FormAlert } from "./FormAlert"
import { useFormAlertContext } from "../hocs/FormAlertProvider"

export const Layout = ({ children, seoProps }) => {
  const { alertState } = useFormAlertContext()
  return (
    <main sx={{ ...theme.basePageStyles.container }}>
      <Seo {...seoProps} />
      <NavHeader />
      {children}
      <FormAlert {...alertState} />
      <Footer />
    </main>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoProps: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    lang: PropTypes.string,
    canonicalUrl: PropTypes.string,
    image: PropTypes.string,
  }),
}
