import { Article } from "../enums/Article"

export const extractExcerpt = (articleBody, length) => {
  const firstTextSlice = articleBody.filter(
    content_slice => content_slice.__typename === Article.ContentSliceTypes.TEXT
  )[0]
  const firstParagraph = firstTextSlice?.primary?.text?.richText[0]?.text

  if (!firstParagraph) return undefined

  if (length > firstParagraph.length) return firstParagraph

  return `${firstParagraph.substring(0, length)}...`
}

export const transformArticleData = prismicArticle => {
  const tags = prismicArticle.data.tags.filter(tag => tag.category.document)
  const contributors = prismicArticle.data.contributors.filter(
    contributor => contributor.contributor.document
  )
  return {
    ...prismicArticle.data,
    tags,
    contributors,
    first_publication_date: prismicArticle.first_publication_date,
    last_publication_date: prismicArticle.last_publication_date,
    url: prismicArticle.url,
  }
}

export const transformArticlesData = allPrismicArticle => {
  return allPrismicArticle.edges.map(edge => {
    return transformArticleData(edge.node)
  })
}
