// Common prop types for re-use
import PropTypes from "prop-types"

import { Article } from "../enums/Article"

export const GatsbyImagePropTypeFields = {
  layout: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  images: PropTypes.shape({
    fallback: PropTypes.exact({
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string,
      sizes: PropTypes.string,
    }),
    sources: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.exact({
          media: PropTypes.string.isRequired,
          type: PropTypes.string,
          sizes: PropTypes.string,
          srcSet: PropTypes.string.isRequired,
        }),
        PropTypes.exact({
          media: PropTypes.string,
          type: PropTypes.string.isRequired,
          sizes: PropTypes.string,
          srcSet: PropTypes.string.isRequired,
        }),
      ])
    ),
  }),
}

export const GatsbyImagePropType = PropTypes.shape(GatsbyImagePropTypeFields)

export const PrismicPlainTextFieldPropType = PropTypes.shape({
  text: PropTypes.string.isRequired,
})

export const PrismicRichTextFieldPropType = PropTypes.shape({
  richText: PropTypes.arrayOf(PropTypes.object),
})

export const SeoFieldsPropType = {
  meta_title: PropTypes.string.isRequired,
  meta_description: PropTypes.string.isRequired,
  language: PropTypes.string,
  canonical_url: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  opengraph_image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
}

export const ResponsiveImagePropTypes = {
  alt: PropTypes.string,
  gatsbyImageData: GatsbyImagePropType,
  thumbnails: PropTypes.shape({
    mobile_portrait: PropTypes.shape({
      gatsbyImageData: GatsbyImagePropType,
    }),
    mobile_landscape: PropTypes.shape({
      gatsbyImageData: GatsbyImagePropType,
    }),
  }),
}

export const CategoryPropTypes = {
  uid: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    gatsbyImageData: GatsbyImagePropType,
  }),
}

export const ArticleTagPropTypes = {
  category: PropTypes.shape({
    document: PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export const ContributorSocialLinkPropTypes = {
  email_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  instagram_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  facebook_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  twitter_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  linkedin_link: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export const ContributorPropTypes = {
  contributor: PropTypes.shape({
    document: PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        profile_picture: PropTypes.shape({
          alt: PropTypes.string,
          gatsbyImageData: GatsbyImagePropType.isRequired,
        }),
        biography: PrismicRichTextFieldPropType.isRequired,
        ...ContributorSocialLinkPropTypes,
      }),
    }),
  }),
}

export const ArticleTextPropTypes = {
  primary: PropTypes.shape({
    text: PrismicRichTextFieldPropType.isRequired,
    style_alignment: PropTypes.oneOf(Object.values(Article.StyleAlignmentText)),
  }),
}

export const ArticleCalloutPropTypes = {
  primary: PropTypes.shape({
    text: PrismicRichTextFieldPropType.isRequired,
    style_alignment: PropTypes.oneOf(Object.values(Article.StyleAlignmentText)),
  }),
}

export const ArticleIframeEmbedPropTypes = {
  primary: PropTypes.shape({
    iframe_link: PropTypes.shape({
      url: PropTypes.string,
    }),
    caption: PrismicRichTextFieldPropType.isRequired,
  }),
}

export const ArticleDividerPropTypes = {
  primary: PropTypes.shape({
    type: PropTypes.shape({
      document: PropTypes.shape({
        data: PropTypes.shape({
          image: GatsbyImagePropType,
        }),
      }),
    }),
  }),
}

export const ArticleQuotePropTypes = {
  primary: PropTypes.shape({
    quote: PrismicRichTextFieldPropType.isRequired,
    quote_attribution: PrismicPlainTextFieldPropType.isRequired,
    style_position: PropTypes.oneOf(Object.values(Article.StylePositionQuote)),
  }),
}

export const ArticleTextWithQuotePropTypes = {
  primary: PropTypes.shape({
    quote: PrismicRichTextFieldPropType.isRequired,
    quote_attribution: PrismicPlainTextFieldPropType.isRequired,
    text: PrismicRichTextFieldPropType.isRequired,
    style_position: PropTypes.oneOf(
      Object.values(Article.StylePositionTextWithQuote)
    ),
  }),
}

export const ArticleImagesPropTypes = {
  primary: PropTypes.shape({
    style_alignment: PropTypes.oneOf(
      Object.values(Article.StyleAlignmentImages)
    ),
    caption: PrismicRichTextFieldPropType,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape(ResponsiveImagePropTypes),
    })
  ).isRequired,
}

export const ArticleImageGalleryPropTypes = {
  primary: PropTypes.shape({
    autoplay: PropTypes.bool.isRequired,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        alt: PropTypes.string,
        gatsbyImageData: GatsbyImagePropType,
      }),
      caption: PrismicRichTextFieldPropType,
    })
  ).isRequired,
}

export const ArticleImagesWithQuotePropTypes = {
  primary: PropTypes.shape({
    quote: PrismicRichTextFieldPropType.isRequired,
    quote_attribution: PrismicPlainTextFieldPropType.isRequired,
    style_position: PropTypes.oneOf(
      Object.values(Article.StylePositionImagesWithQuote)
    ),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        alt: PropTypes.string,
        gatsbyImageData: GatsbyImagePropType,
      }),
    })
  ).isRequired,
}

export const ArticleDataPropTypes = {
  canonical_url: PropTypes.shape({
    url: PropTypes.string,
  }),
  language: PropTypes.string,
  needs_paid_access: PropTypes.bool,
  is_featured: PropTypes.bool,
  cover_image: PropTypes.shape(ResponsiveImagePropTypes),
  tags: PropTypes.arrayOf(PropTypes.shape(ArticleTagPropTypes)),
  publication_date: PropTypes.string,
  title: PrismicPlainTextFieldPropType,
  body: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ArticleDividerPropTypes),
      PropTypes.shape(ArticleQuotePropTypes),
      PropTypes.shape(ArticleTextPropTypes),
      PropTypes.shape(ArticleCalloutPropTypes),
      PropTypes.shape(ArticleTextWithQuotePropTypes),
      PropTypes.shape(ArticleImagesPropTypes),
      PropTypes.shape(ArticleImagesWithQuotePropTypes),
      PropTypes.shape(ArticleImageGalleryPropTypes),
    ])
  ),
  contributors: PropTypes.arrayOf(PropTypes.shape(ContributorPropTypes)),
}

export const ArticlePropTypes = {
  uid: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  first_publication_date: PropTypes.string,
  last_publication_date: PropTypes.string,
  data: PropTypes.shape(ArticleDataPropTypes),
}

export const TransformedArticlePropTypes = {
  uid: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  first_publication_date: PropTypes.string,
  last_publication_date: PropTypes.string,
  ...ArticleDataPropTypes,
}

export const JournazineDataPropTypes = {
  cover_image: PropTypes.shape({
    alt: PropTypes.string,
    gatsbyImageData: GatsbyImagePropType,
    thumbnails: PropTypes.shape({
      mobile_portrait: PropTypes.shape({
        gatsbyImageData: GatsbyImagePropType,
      }),
      mobile_landscape: PropTypes.shape({
        gatsbyImageData: GatsbyImagePropType,
      }),
    }),
  }),
  issue_title: PrismicPlainTextFieldPropType,
  issue_description: PrismicRichTextFieldPropType,
  short_issue_description: PrismicRichTextFieldPropType,
  publication_date: PropTypes.string,
  preview_link: PropTypes.shape({
    url: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section_name: PropTypes.string,
    })
  ),
}

export const JournazinePropTypes = {
  type: PropTypes.string,
  uid: PropTypes.string,
  url: PropTypes.string,
  data: PropTypes.shape(JournazineDataPropTypes),
}

export const OpenCallPropTypes = {
  uid: PropTypes.string,
  data: PropTypes.shape({
    cover_image: PropTypes.shape({
      alt: PropTypes.string,
      gatsbyImageData: GatsbyImagePropType,
    }),
    title: PrismicPlainTextFieldPropType,
    description: PrismicRichTextFieldPropType,
  }),
}

const CrystallizeSingleLineContentPropTypes = {
  text: PropTypes.string,
}

const CrystallizeRichTextContentPropTypes = {
  json: PropTypes.objects,
}

const CrystallizeImagePropTypes = {
  url: PropTypes.string,
  altText: PropTypes.string,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    })
  ),
}

export const CrystallizeItemPropTypes = {
  shape: PropTypes.shape({
    name: PropTypes.string,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.shape(CrystallizeSingleLineContentPropTypes),
        PropTypes.shape(CrystallizeRichTextContentPropTypes),
        PropTypes.shape({
          images: PropTypes.arrayOf(PropTypes.shape(CrystallizeImagePropTypes)),
        }),
      ]),
    })
  ),
}

export const BaseCrystallizeProductVariantPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  sku: PropTypes.string,
  price: PropTypes.number,
  priceVariants: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number,
      identifier: PropTypes.string,
      currency: PropTypes.string,
    })
  ),
  stock: PropTypes.number,
  isDefault: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape(CrystallizeImagePropTypes)),
}

export const CrystallizeProductPropTypes = {
  id: PropTypes.string,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      ...BaseCrystallizeProductVariantPropTypes,
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          attribute: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
}

export const TransformedCrystallizeProductPropTypes = {
  shape: PropTypes.shape({
    name: PropTypes.string,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  components: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.shape(CrystallizeSingleLineContentPropTypes),
        PropTypes.shape(CrystallizeRichTextContentPropTypes),
        PropTypes.shape({
          images: PropTypes.arrayOf(PropTypes.shape(CrystallizeImagePropTypes)),
        }),
      ]),
    })
  ),
  variants: PropTypes.objectOf(
    PropTypes.shape({
      ...BaseCrystallizeProductVariantPropTypes,
      attributes: PropTypes.object,
    })
  ),
  defaultVariant: PropTypes.shape({
    ...BaseCrystallizeProductVariantPropTypes,
    attributes: PropTypes.object,
  }),
}
