/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Alert, Text } from "theme-ui"

import theme from "../gatsby-plugin-theme-ui"

export const FormAlert = ({ result, msg, isVisible }) => (
  <Alert
    data-testid="form-alert"
    variant={result === "success" ? "success" : "error"}
    sx={{
      opacity: isVisible ? 0.9 : 0,
      visibility: isVisible ? "visible" : "hidden",
    }}
  >
    <Text
      dangerouslySetInnerHTML={{ __html: msg }}
      sx={{
        a: {
          ...theme.links.textWhite,
        },
      }}
    />
  </Alert>
)
FormAlert.propTypes = {
  result: PropTypes.string,
  msg: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
}
