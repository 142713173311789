/** @jsx jsx */

import { jsx } from "theme-ui"
import { Link as PrismicLink } from "prismic-reactjs"

import { Link } from "./Link"
import linkResolver from "../utils/linkResolver"

// This function is used for serializing links in Prismic rich text fields
// The prismic-reactjs Link helper helps to resolve the URL of the link
// depending on the link type (Web, Document, Media)
// We pass that to our custom Link component which helps to differentiate whether to render
// a Gatsby Link for internal links or a normal ThemeUI Link

export const RichTextLinkSerializer = (
  type,
  element,
  content,
  children,
  index
) => {
  const to = PrismicLink.url(element.data, linkResolver)

  return (
    <Link key={index} to={to}>
      {content}
    </Link>
  )
}
