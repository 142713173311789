/** @jsx jsx */

import { Link as GatsbyLink } from "gatsby"
import { jsx, Link as ThemeUILink } from "theme-ui"
import PropTypes from "prop-types"

// Gatsby's Link component is only meant for internal links
// So we encapsulate logic to differentiate between internal and external links
// in this wrapper component

export const Link = ({ children, to, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <ThemeUILink as={GatsbyLink} to={to} {...other}>
        {children}
      </ThemeUILink>
    )
  }
  return (
    <ThemeUILink href={to} {...other}>
      {children}
    </ThemeUILink>
  )
}
Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  variant: PropTypes.string,
}

export default Link
