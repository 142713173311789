import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import defaultOgImage from "../images/main-graphic-og-image-size.png"
import { OpenGraphTypes, isValidOpenGraphType } from "../utils/seoUtils"

const KEYWORD_DELIMITER = ", "

const getAuthors = (type, authors, defaultAuthor) => {
  if (type === OpenGraphTypes.ARTICLE) {
    return authors && authors.length > 0 ? authors : [defaultAuthor]
  }

  return [defaultAuthor]
}
getAuthors.propTypes = {
  type: PropTypes.oneOf(Object.values(OpenGraphTypes)),
  authors: PropTypes.arrayOf(PropTypes.string),
  defaultAuthor: PropTypes.string,
}

const getKeywords = (type, articleTags, defaultKeywordsString) => {
  if (type === OpenGraphTypes.ARTICLE) {
    return articleTags && articleTags.length > 0
      ? articleTags
      : defaultKeywordsString.split(KEYWORD_DELIMITER)
  }

  return defaultKeywordsString.split(KEYWORD_DELIMITER)
}
getKeywords.propTypes = {
  type: PropTypes.oneOf(Object.values(OpenGraphTypes)),
  articleTags: PropTypes.arrayOf(PropTypes.string),
  defaultKeywordsString: PropTypes.string,
}

const constructMetaTagArray = (tagAttribute, contents) =>
  contents.map(content => ({
    [tagAttribute.attribute]: tagAttribute.value,
    content,
  }))
constructMetaTagArray.propTypes = {
  tagAttribute: PropTypes.shape({
    attribute: PropTypes.string,
    value: PropTypes.string,
  }),
  contents: PropTypes.arrayOf(PropTypes.string),
}

const constructOgArticleMetaTags = ({
  authors,
  tags,
  publishedTime,
  modifiedTime,
}) => {
  const publishedTimeTag = {
    property: `og:article:published_time`,
    content: publishedTime,
  }
  const modifiedTimeTag = {
    property: `og:article:modified_time`,
    content: modifiedTime,
  }

  return constructMetaTagArray(
    { attribute: "property", value: "og:article:author" },
    authors
  )
    .concat(
      constructMetaTagArray(
        { attribute: "property", value: "og:article:tag" },
        tags
      )
    )
    .concat(publishedTime ? publishedTimeTag : [])
    .concat(modifiedTime ? modifiedTimeTag : [])
}

export const SeoBase = ({
  siteMetadata,
  title,
  description,
  lang,
  canonicalUrlPath,
  type,
  image,
  authors,
  tags,
  publishedTime,
  modifiedTime,
  scripts = [],
}) => {
  const fullTitle =
    title && title !== siteMetadata.title
      ? `${title} | ${siteMetadata.title}`
      : siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const metaLang = lang || siteMetadata.locale

  const authorsList = getAuthors(type, authors, siteMetadata.author)
  const metaAuthor = authorsList[0]
  const keywordslist = getKeywords(type, tags, siteMetadata.keywords)
  const metaKeywords = keywordslist.join(", ")

  const linkCanonicalUrl = canonicalUrlPath
    ? `${siteMetadata.siteUrl}${canonicalUrlPath}`
    : siteMetadata.siteUrl
  const metaOgType = isValidOpenGraphType(type) ? type : "website"
  const metaOgImage = image || `${siteMetadata.siteUrl}${defaultOgImage}`

  const ogArticleMetaTags =
    type === OpenGraphTypes.ARTICLE
      ? constructOgArticleMetaTags({
          authors: authorsList,
          tags: keywordslist,
          publishedTime,
          modifiedTime,
        })
      : []

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLang,
      }}
      title={fullTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: metaAuthor,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:type`,
          content: metaOgType,
        },
        {
          property: `og:title`,
          content: fullTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaOgImage,
        },
        {
          property: `og:url`,
          content: linkCanonicalUrl,
        },
      ].concat(ogArticleMetaTags)}
      link={[
        {
          rel: "canonical",
          href: linkCanonicalUrl,
        },
      ].concat(
        process.env.GATSBY_PRECONNECT_ORIGINS.split(",").map(origin => ({
          rel: "preconnect",
          href: origin,
        }))
      )}
    >
      {scripts.map((scriptAttrs, i) => (
        <script key={i} {...scriptAttrs}></script>
      ))}
    </Helmet>
  )
}
const SeoBaseDataProps = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  canonicalUrlPath: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  authors: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  publishedTime: PropTypes.string,
  modifiedTime: PropTypes.string,
  scripts: PropTypes.arrayOf(PropTypes.object),
}
SeoBase.propTypes = {
  siteMetadata: PropTypes.shape({
    siteUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    author: PropTypes.string,
    keywords: PropTypes.string,
    locale: PropTypes.string,
  }),
  ...SeoBaseDataProps,
}

export const Seo = data => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            keywords
            locale
          }
        }
      }
    `
  )

  return <SeoBase siteMetadata={site.siteMetadata} {...data} />
}
Seo.propTypes = {
  data: PropTypes.shape({
    ...SeoBaseDataProps,
  }),
}
