/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { Image as CrystallizeImage } from "@crystallize/react-image"
import { BgImage } from "gbimage-bridge"
import PropTypes from "prop-types"

import { MediaMatcher } from "./MediaMatcher"
import { ResponsiveImagePropTypes } from "../utils/propTypes"
import { isCrystallizeImage } from "../utils/eCommerceUtils"
import { fallbackImage } from "../hooks/fallbackImage"

const ThumbnailType = {
  MOBILE_PORTRAIT: "mobile_portrait",
  MOBILE_LANDSCAPE: "mobile_landscape",
}

const thumbnailTypeToResponsiveArrayIndex = thumbnailType => {
  const map = {
    [ThumbnailType.MOBILE_PORTRAIT]: 0,
    [ThumbnailType.MOBILE_LANDSCAPE]: 1,
  }

  return map[thumbnailType] || 2
}

export const ResponsiveImage = ({
  image,
  sizes,
  predicted,
  isBackgroundImage,
  children,
  sx,
  bgImgStyles,
  ...rest
}) => {
  if (isCrystallizeImage(image)) {
    return <CrystallizeImage {...image} sizes={sizes} sx={sx} {...rest} />
  }

  const fallback = fallbackImage()

  const props = thumbnailType => {
    const gatsbyImageData =
      thumbnailType && image.thumbnails && image.thumbnails[thumbnailType]
        ? image.thumbnails[thumbnailType].gatsbyImageData
        : image.gatsbyImageData

    const finalGatsbyImageData = gatsbyImageData
      ? gatsbyImageData
      : fallback.gatsbyImageData

    const finalSizes = sizes
      ? sizes[thumbnailTypeToResponsiveArrayIndex(thumbnailType)]
      : null

    if (finalSizes) {
      finalGatsbyImageData.images.fallback.sizes = finalSizes
    }

    return {
      sx,
      ...(isBackgroundImage ? { style: bgImgStyles } : {}),
      alt: image.alt,
      objectFit: "cover",
      image: finalGatsbyImageData,
      // Contra gatsby-plugin-image docs, they do not seem to default to loading="lazy" post-hydration,
      // although the img tags on the SSRed HTML do have that attribute correctly set
      loading: "lazy",
      ...rest,
    }
  }

  return (
    <MediaMatcher.ServerRender predicted={predicted || "mobilePortrait"}>
      <MediaMatcher.Matcher
        mobilePortrait={
          isBackgroundImage ? (
            <BgImage {...props(ThumbnailType.MOBILE_PORTRAIT)}>
              {children}
            </BgImage>
          ) : (
            <GatsbyImage {...props(ThumbnailType.MOBILE_PORTRAIT)} />
          )
        }
        mobileLandscape={
          isBackgroundImage ? (
            <BgImage {...props(ThumbnailType.MOBILE_LANDSCAPE)}>
              {children}
            </BgImage>
          ) : (
            <GatsbyImage {...props(ThumbnailType.MOBILE_LANDSCAPE)} />
          )
        }
        tablet={
          isBackgroundImage ? (
            <BgImage {...props()}>{children}</BgImage>
          ) : (
            <GatsbyImage {...props()} />
          )
        }
      />
    </MediaMatcher.ServerRender>
  )
}
ResponsiveImage.propTypes = {
  image: PropTypes.shape(ResponsiveImagePropTypes),
  sizes: PropTypes.arrayOf(PropTypes.string),
  predicted: PropTypes.oneOf([
    "mobilePortrait",
    "mobileLandscape",
    "tablet",
    "desktop",
  ]),
  isBackgroundImage: PropTypes.bool,
  children: PropTypes.node,
  sx: PropTypes.object,
  bgImgStyles: PropTypes.object,
}
